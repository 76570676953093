// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("N:\\ALIM\\repo\\web-dinsos\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-berita-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\berita.js" /* webpackChunkName: "component---src-pages-berita-js" */),
  "component---src-pages-data-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-data-data-fmotm-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-fmotm.js" /* webpackChunkName: "component---src-pages-data-data-fmotm-js" */),
  "component---src-pages-data-data-lks-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-lks.js" /* webpackChunkName: "component---src-pages-data-data-lks-js" */),
  "component---src-pages-data-data-lokasi-rawan-pmks-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-lokasi-rawan-pmks.js" /* webpackChunkName: "component---src-pages-data-data-lokasi-rawan-pmks-js" */),
  "component---src-pages-data-data-opendata-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-opendata.js" /* webpackChunkName: "component---src-pages-data-data-opendata-js" */),
  "component---src-pages-data-data-petugas-p-3-s-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-petugas-p3s.js" /* webpackChunkName: "component---src-pages-data-data-petugas-p-3-s-js" */),
  "component---src-pages-data-data-pkh-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-pkh.js" /* webpackChunkName: "component---src-pages-data-data-pkh-js" */),
  "component---src-pages-data-data-uepkube-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\data\\data-uepkube.js" /* webpackChunkName: "component---src-pages-data-data-uepkube-js" */),
  "component---src-pages-files-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-galeri-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\galeri.js" /* webpackChunkName: "component---src-pages-galeri-js" */),
  "component---src-pages-index-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infografis-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\infografis.js" /* webpackChunkName: "component---src-pages-infografis-js" */),
  "component---src-pages-kontak-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\kontak.js" /* webpackChunkName: "component---src-pages-kontak-js" */),
  "component---src-pages-login-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-search-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\news-search.js" /* webpackChunkName: "component---src-pages-news-search-js" */),
  "component---src-pages-news-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-profil-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-unduhan-js": () => import("N:\\ALIM\\repo\\web-dinsos\\src\\pages\\unduhan.js" /* webpackChunkName: "component---src-pages-unduhan-js" */)
}

