module.exports = [{
      plugin: require('N:/ALIM/repo/web-dinsos/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"main":"#1572e8"},"secondary":{"main":"#f03a47"}}},"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('N:/ALIM/repo/web-dinsos/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('N:/ALIM/repo/web-dinsos/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('N:/ALIM/repo/web-dinsos/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
